import React, { useContext } from "react";
import VideoGallery from "../components/VideoGallery";
import Gallery from "../components/Gallery";
import { graphql, PageProps } from "gatsby";
import { BGLoadedContext } from "../contexts/BGLoadedProvider";
import "../style.scss";

type DataProps = {
  contentfulPortfolioPage: {
    sections: {
      references: [
        {
          assets: {
            references: [
              {
                assets: [
                  {
                    file: {
                      url: string;
                      fileName: string;
                    };
                    description: string;
                  }
                ];
              }
            ];
          };
          columns: number;
          subheader: null | string;
          type: string;
        }
      ];
    };
    header: string;
  };
};

export default function Portfolio({ data }: PageProps<DataProps>) {
  const { isBGLoaded } = useContext(BGLoadedContext);
  const refs = data.contentfulPortfolioPage.sections.references;
  return (
    <main className="portfolio-page">
      <h1>{data.contentfulPortfolioPage.header}</h1>
      <section className="portfolio-section">
        {isBGLoaded &&
          refs.map((ref, index) => {
            if (ref.type === "videoset") {
              return (
                <VideoGallery
                  key={index}
                  header={ref.subheader}
                  references={ref.assets.references}
                  columns={ref.columns}
                />
              );
            }
            return (
              <Gallery
                key={index}
                header={ref.subheader}
                type={ref.type}
                columns={ref.columns}
                assets={ref.assets.references[0].assets}
              />
            );
          })}
      </section>
    </main>
  );
}

export const query = graphql`
  query ($id: String!) {
    contentfulPortfolioPage(id: { eq: $id }) {
      sections {
        references {
          assets {
            references {
              ... on ContentfulYoutube {
                url
                title
                tooltip
              }
              ... on ContentfulImageset {
                assets {
                  file {
                    fileName
                    url
                  }
                  description
                  gatsbyImageData(
                    formats: JPG
                    width: 500
                    layout: CONSTRAINED
                    resizingBehavior: SCALE
                    placeholder: DOMINANT_COLOR
                    quality: 100
                  )
                }
              }
              ... on ContentfulGifset {
                assets {
                  file {
                    url
                    fileName
                  }
                  description
                  gatsbyImageData(
                    width: 450
                    layout: CONSTRAINED
                    resizingBehavior: SCALE
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
          }
          columns
          subheader
          type
        }
      }
      header
    }
  }
`;
