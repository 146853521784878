import React, { useEffect } from "react";
import "../style.scss";
import ReactTooltip from "react-tooltip";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes";
import { Masonry } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { InView } from "react-intersection-observer";

interface VideoGalleryProps {
  header: string | null;
  columns: number & typeof defaultProps.columns;
  references: Array<{
    [key: string]: any;
  }>;
}

const defaultProps = {
  references: [
    {
      url: "",
      tooltip: "",
    },
  ],
  columns: 1,
  type: "imageset", // imageset, gifset, videoset
};

const VideoGallery = (props: VideoGalleryProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.only("xs"));
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  const isBrowser = typeof window !== "undefined";

  return (
    <React.Fragment>
      <div className="gallery-wrapper">
        <h3>{props.header}</h3>
        <Masonry
          columns={{
            xs: 1,
            sm: Math.min(props.columns, 2),
            md: Math.min(props.columns, 2),
            lg: props.columns,
          }}
          spacing={2}
          defaultColumns={props.columns}
        >
          {isBrowser &&
            props.references.map((video, index) => (
              <InView
                key={index}
                as="div"
                className="video-container fade-in-section"
                style={{
                  paddingTop: `calc(56.25%/${matches ? 1 : props.columns})`,
                }}
                onChange={(inView, entry) => {
                  inView
                    ? entry.target.classList.add("is-visible")
                    : entry.target.classList.remove("is-visible");
                }}
              >
                <iframe
                  data-tip={video.tooltip}
                  className="lazyload"
                  width="560"
                  height="315"
                  data-src={`https://tube.rvere.com/embed?v=${video.url}`}
                  frameBorder="0"
                  allowFullScreen
                />
              </InView>
            ))}
        </Masonry>
      </div>
    </React.Fragment>
  );
};
VideoGallery.defaultProps = defaultProps;

export default VideoGallery;
